import React from 'react';
import useClickAndDoubleClick from '../../../hooks/useClickAndDoubleClick';

type Props = {
  img: string;
  className: string;
  onClick?: () => void;
  onDoubleClick?: () => void;
  selected?: boolean;
  style?: React.CSSProperties;
};

const ImageCard = ({
  img,
  selected = false,
  onClick = undefined,
  onDoubleClick = undefined,
  className = '',
  style = undefined,
}: Props) => {
  const handleClick = useClickAndDoubleClick(onClick, onDoubleClick);

  return (
    <div
      style={style}
      onClick={handleClick}
      className={`${className} relative flex h-full w-full flex-col items-center justify-center ${
        selected ? 'bg-tertiary p-1' : ''
      }`}
    >
      <img
        draggable={false}
        className=" h-full w-full overflow-hidden  object-cover"
        src={img}
        alt="card"
      />
    </div>
  );
};

export default ImageCard;
