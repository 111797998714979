type Props = {
  children: string;
  onClick?: () => void;
  onDelete?: () => void;
  className?: string;
};

const Tag = ({
  children,
  className = '',
  onClick = undefined,
  onDelete = undefined,
}: Props) => {
  return (
    <span
      onClick={() => onClick && onClick()}
      id="badge-dismiss-indigo"
      className={`${className} mr-2 inline-flex items-center rounded bg-tertiary px-2 py-1 text-xs  font-medium text-primary  ${
        onClick ? 'hover:cursor-pointer hover:bg-primary hover:text-white' : ''
      } `}
    >
      {children}
      {onDelete && (
        <button
          onClick={() => onDelete()}
          type="button"
          className="ml-2 inline-flex items-center rounded-sm bg-transparent p-0.5 text-sm text-primary hover:bg-tertiary"
          data-dismiss-target="#badge-dismiss-indigo"
          aria-label="Remove"
        >
          <svg
            aria-hidden="true"
            className="h-3.5 w-3.5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Remove badge</span>
        </button>
      )}
    </span>
  );
};

export default Tag;
