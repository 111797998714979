const Page = ({
  children,
  className = '',
}: {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
}) => {
  return (
    <main
      className={`${className} flex h-fit min-h-full w-full flex-grow flex-col items-center justify-start `}
    >
      {children}
    </main>
  );
};

export default Page;
