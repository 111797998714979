import React from 'react';

function Layout({ children }: React.PropsWithChildren) {
  return (
    <div className="m-5 flex min-h-full max-w-full flex-col justify-center rounded-md bg-slate-50 p-5 text-xl text-black shadow-lg">
      {children}
    </div>
  );
}

export default Layout;
