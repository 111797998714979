import { useState, useEffect } from 'react';

const useClickAndDoubleClick = (
  onClick?: () => void,
  onDoubleClick?: () => void,
  delay = 200,
) => {
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (clicks === 1) {
        onClick && onClick();
      } else if (clicks === 2) {
        onDoubleClick && onDoubleClick();
      }
      setClicks(0);
    }, delay);

    return () => clearTimeout(timer);
  }, [clicks, delay, onClick, onDoubleClick]);

  const handleClick = () => {
    setClicks((prev) => prev + 1);
  };

  return handleClick;
};

export default useClickAndDoubleClick;
