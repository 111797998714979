import { ChangeEvent, useState } from 'react';
import { useDebounce, useImages, useTags } from '../../hooks';

import { IImage, ITag } from '../../models';
import { Button } from '../Button';
import Tag from '../Tag/Tag';
import TextArea from '../TextArea/TextArea';
import { useParams } from 'react-router-dom';
import Alert from '../../services/alerts/AlertService';
import TrashIcon from '../Icons/TrashIcon';
type Props = {
  image: IImage;
  close: () => void;
};

const EditImage = ({ image, close }: Props) => {
  const [imageData, setImageData] = useState<IImage>(image);
  const [searchTerm, setSearchTerm] = useState('');
  const { deleteImage } = useImages();

  const { getTags, contextTags, setContextTags } = useTags();
  const { updateImage } = useImages();

  const { collectionId } = useParams();

  useDebounce(searchTerm, 500, () => {
    getTags({ search: searchTerm }).then((data: ITag[]) =>
      setContextTags(data),
    );
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setImageData({ ...imageData, [name]: value });
  };

  const handleTags = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes(',')) {
      const newTag: ITag = {
        id: null,
        name: e.target.value.split(',')[0],
      };
      addTag(newTag);
      e.target.value = '';
    }
  };

  const removeTag = (tag: ITag) => {
    if (!imageData.tags) return;
    const tagIndex = imageData.tags.indexOf(tag);
    const auxArray = [...imageData.tags];
    auxArray?.splice(tagIndex, 1);
    setImageData({
      ...imageData,
      tags: auxArray,
    });
  };

  const addTag = (newTag: ITag) => {
    if (imageData.tags?.includes(newTag)) return;
    setImageData({
      ...imageData,
      tags: imageData.tags ? [...imageData.tags, newTag] : [newTag],
    });
  };

  const handleSave = () => {
    if (!collectionId) return;
    updateImage(+collectionId, imageData)
      .then(() => close())
      .catch((err: Error) => alert(err.message));
  };

  const handleDeleteImage = async (image: IImage) => {
    if (!collectionId) return;
    deleteImage(+collectionId, image.id)
      .then(() => close())
      .catch((err: any) =>
        Alert.error({ title: err.name, message: err.message }),
      );
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex w-full flex-col gap-4 rounded-md  bg-white p-5 text-left sm:w-3/4 md:p-10  lg:w-1/2"
    >
      <div className=" h-64 w-full">
        <img
          className="h-full w-full rounded-md object-contain"
          src={image.imgUrl}
          alt={'editing'}
        />
      </div>
      <div className="w-full">
        <h4 className=" md:text-md my-1 text-sm font-semibold   text-gray-800">
          Description
        </h4>
        <TextArea
          data-cy="description-area"
          rows={6}
          className="w-full"
          name="description"
          aria-label={'Description'}
          onChange={handleChange}
          defaultValue={imageData.description}
        ></TextArea>
      </div>

      <div>
        <h4 className="lg:text-md mb-1 text-sm font-semibold  text-gray-800">
          Tags
        </h4>
        <div className="flex h-24 max-h-fit w-full flex-row items-start gap-2">
          <div className="flex h-full w-full flex-col">
            <input
              data-cy="tags-input"
              className=" h-fit w-full border border-gray-300 bg-gray-50 p-2 text-sm  text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500"
              type="text"
              name="tags"
              onChange={(e) => {
                handleSearch(e);
                handleTags(e);
              }}
              placeholder="Tags separated by commas"
            ></input>
            <div className="h-full max-h-full w-full flex-grow overflow-y-auto">
              {contextTags &&
                contextTags.length > 0 &&
                contextTags.map((tag: ITag, i: number) => (
                  <Tag
                    aria-label={`add-${tag.name}`}
                    onClick={() => addTag(tag)}
                    key={`suggested-${tag.name}-${i}`}
                    className="my-1"
                  >
                    {tag.name}
                  </Tag>
                ))}
            </div>
          </div>

          <div
            data-cy="tags-container"
            className="flex max-h-24 w-full flex-row flex-wrap items-start overflow-y-auto "
          >
            {imageData.tags &&
              imageData.tags.map((tag, i) => (
                <Tag
                  aria-label={`remove-${tag.name}`}
                  key={`imagetag-${tag.name}-${i}`}
                  onDelete={() => removeTag(tag)}
                  className="my-1"
                >
                  {tag.name}
                </Tag>
              ))}
          </div>
        </div>
      </div>

      <div className="flex w-full flex-row justify-between">
        <button
          data-cy="delete-image-button"
          className={
            'rounded-lg bg-tertiary p-2 text-primary hover:bg-primary hover:text-white'
          }
          onClick={() => handleDeleteImage(image)}
        >
          <TrashIcon className="h-6 w-6"></TrashIcon>
        </button>
        <div className="flex w-full flex-row justify-end gap-5">
          <Button
            data-cy="discard-image-button"
            className="rounded-lg bg-tertiary text-primary hover:bg-primary hover:text-white"
            onClick={close}
          >
            Discard
          </Button>
          <Button
            data-cy="save-image-button"
            className="rounded-lg bg-secondary text-white hover:bg-primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditImage;
