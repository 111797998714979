import { useState } from 'react';

const useSelectableElements = (
  limit?: number,
): [unknown[], (element: unknown) => void, (elements?: unknown[]) => void] => {
  const [selectedElements, setSelectedElements] = useState<unknown[]>(
    [] as unknown[],
  );

  const handleSelection = (element: unknown) => {
    const index = selectedElements.indexOf(element);
    if (index === -1) {
      if (limit && selectedElements.length >= limit) {
        return;
      }

      setSelectedElements([...selectedElements, element]);
    } else {
      const auxArr = [...selectedElements];
      auxArr.splice(index, 1);
      setSelectedElements(auxArr);
    }
  };

  const clearSelections = (exceptions: unknown[] = []) => {
    setSelectedElements(exceptions);
  };

  return [selectedElements, handleSelection, clearSelections];
};

export { useSelectableElements };
