import React, { createContext, useState } from 'react';
import { IImage } from '../../models';

import { API } from '../../services/url';
import { useUserServices } from '../../hooks';

interface IImageContext {
  Images: IImage[] | null;
  setImages: (Images: IImage[]) => void;
  getImages: (collectionId: number, query?: ImageQuery) => Promise<IImage[]>;
  getImageById: (collectionId: number, imageId: number) => Promise<IImage>;
  updateImage: (collectionId: number, image: IImage) => Promise<void>;
  deleteImage: (collectionId: number, ImageId: number) => Promise<void>;
}

const ImageContext = createContext({} as IImageContext);

interface ImageQuery {
  tags?: string;
  search: string;
}

function ImageProvider({ children }: { children: React.ReactNode }) {
  const { getAccessToken } = useUserServices();

  const [Images, setContextImages] = useState<IImage[] | null>(null);

  const setImages = (Images: IImage[]) => {
    setContextImages(Images);
  };

  const updateImage = async (collectionId: number, image: IImage) => {
    const imageObject = JSON.stringify(image);
    const options = {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: imageObject,
    };
    const response = await fetch(
      `${API}collections/${collectionId}/images/${image.id}`,
      options,
    );
    if (!response.ok) {
      throw new Error('Error updating image');
    }
  };

  const getImageById = async (
    collectionId: number,
    imageId: number,
  ): Promise<IImage> => {
    const response = await fetch(
      `${API}collections/${collectionId}/images/${imageId}`,
      {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
      },
    );
    const data = await response.json();
    return data;
  };

  const getImages = async (
    collectionId: number,
    query?: ImageQuery,
  ): Promise<IImage[]> => {
    if (query?.search) {
      const response = await fetch(
        `${API}collections/${collectionId}/images?tags=${query.search}`,
        {
          headers: { Authorization: `Bearer ${await getAccessToken()}` },
        },
      );
      const data = await response.json();
      return data;
    }
    const response = await fetch(`${API}collections/${collectionId}/images`, {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    const data = await response.json();
    return data;
  };

  const deleteImage = async (collectionId: number, imageId: number) => {
    const options = {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    };
    const response = await fetch(
      `${API}collections/${collectionId}/images/${imageId}`,
      options,
    );
    if (!response.ok) {
      const err = await response.json();
      throw err;
    }
  };

  return (
    <ImageContext.Provider
      value={{
        Images,
        getImages,
        getImageById,
        setImages,
        updateImage,
        deleteImage,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
}

export { ImageProvider, ImageContext };
