import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { UserServicesProvider } from './components';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserServicesProvider>
        <App />
      </UserServicesProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
