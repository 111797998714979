import { Outlet, Navigate } from 'react-router-dom';
import { useUserServices } from '../hooks';
import Loader from '../components/Loader/Loader';
import Page from '../components/Page/Page';

function AuthGuard() {
  const { session, loadingSession } = useUserServices();
  if (loadingSession)
    return (
      <Page>
        <div className=" flex h-full w-full flex-grow flex-col items-center justify-center">
          <Loader className="h-20 w-20"></Loader>
        </div>
      </Page>
    );
  return session ? <Outlet /> : <Navigate replace to={'/signin'} />;
}

export default AuthGuard;
