import { useState, useEffect } from 'react';

function useDebounce(
  value: unknown,
  delay: number,
  onDebouncedValue: () => void,
) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId =
      value !== debouncedValue &&
      setTimeout(() => {
        setDebouncedValue(value);
        onDebouncedValue();
      }, delay);

    return () => clearTimeout(timeoutId as NodeJS.Timeout);
  }, [value, debouncedValue, delay, onDebouncedValue]);
}
export { useDebounce };
