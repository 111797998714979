import React, { createContext, useEffect, useState } from 'react';

import IPresentation, {
  ICreatePresentation,
} from '../../models/presentation.model';
import { API } from '../../services/url';
import { useUserServices } from '../../hooks';

interface IPresentationContext {
  presentations: IPresentation[] | null;
  setPresentations: (presentations: IPresentation[]) => void;
  getPresentations: (query?: PresentationQuery) => Promise<IPresentation[]>;
  getPresentationById: (presentationId: number) => Promise<IPresentation>;
  deletePresentation: (presentationId: number) => Promise<void>;
  createPresentation: (
    presentation: ICreatePresentation,
  ) => Promise<IPresentation>;
}

const PresentationContext = createContext({} as IPresentationContext);

interface PresentationQuery {
  search?: string;
  orderBy?: string;
}

function PresentationProvider({ children }: { children: React.ReactNode }) {
  const { session, getAccessToken } = useUserServices();
  const [presentations, setContextPresentations] = useState<
    IPresentation[] | null
  >(null);

  const setPresentations = (presentations: IPresentation[]) => {
    setContextPresentations(presentations);
  };
  const createPresentation = async (
    presentation: ICreatePresentation,
  ): Promise<IPresentation> => {
    const response = await fetch(`${API}presentations`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify(presentation),
    });

    if (!response.ok) throw Error('Failed to create presentation');
    const data = response.json();
    return data;
  };
  const getPresentationById = async (
    presentationId: number,
  ): Promise<IPresentation> => {
    const response = await fetch(`${API}presentations/${presentationId}`, {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    const data = await response.json();
    return data;
  };
  const deletePresentation = async (presentationId: number) => {
    const response = await fetch(`${API}presentations/${presentationId}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    if (!response.ok) throw Error('Failed to delete presentation');
  };

  const getPresentations = async (
    query?: PresentationQuery,
  ): Promise<IPresentation[]> => {
    if (query?.search) {
      const response = await fetch(
        `${API}presentations?title=${query.search}`,
        { headers: { Authorization: `Bearer ${await getAccessToken()}` } },
      );
      const data = await response.json();
      return data;
    }
    const response = await fetch(`${API}presentations`, {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    getAccessToken().then((token) => {
      if (presentations === null) {
        fetch(`${API}presentations`, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((data) => data.json())
          .then((data) => setPresentations(data as unknown as IPresentation[]));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentations, session?.accessToken]);

  return (
    <PresentationContext.Provider
      value={{
        presentations,
        getPresentations,
        getPresentationById,
        setPresentations,
        createPresentation,
        deletePresentation,
      }}
    >
      {children}
    </PresentationContext.Provider>
  );
}

export { PresentationProvider, PresentationContext };
