import React, { useEffect, useState } from 'react';
import { Button, Input } from '../../../components';
import { useSlides } from '../../../components/contexts/SlidesProvider';
import useKeys from '../../../hooks/useKeys';
import IPresentation from '../../../models/presentation.model';
import CarouselSlides from './CarouselSlides';
import favicon from '../../../assets/favicon.png';

import ReactDOM from 'react-dom';
import ISlide from '../../../models/presentationImage.model';
import WindowPresentation from './WindowPresentation';
import useTimer from '../../../hooks/useTimer';
import ClockIcon from '../../../components/Icons/ClockIcon';

type Props = {
  presentation: IPresentation;
};
const PresentationPreview = ({ presentation }: Props) => {
  const { currentSlide, setCurrentSlideImage } = useSlides();
  const [newWindow, setNewWindow] = useState<Window | null>(null);
  const [slideDurationTime, setSlideDurationTime] = useState<number>(5);

  const handlePresentedSlide = (image: ISlide) => {
    setCurrentSlideImage(image);
  };

  const handleSlideDurationTimeChange = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    setSlideDurationTime(+e.currentTarget.value);
  };

  const nextSlide = () => {
    if (!currentSlide) return;
    const images = [...presentation.slides];
    const currentIndex = images.indexOf(currentSlide);
    let nextIndex = currentIndex;
    nextIndex = Math.min(currentIndex + 1, images.length - 1);
    handlePresentedSlide(images[nextIndex]);
  };

  const [isPlaying, start, stop] = useTimer(slideDurationTime, nextSlide);

  const prevSlide = () => {
    if (!currentSlide) return;
    const images = [...presentation.slides];
    const currentIndex = images.indexOf(currentSlide);
    let nextIndex = currentIndex;
    nextIndex = Math.max(currentIndex - 1, 0);
    handlePresentedSlide(images[nextIndex]);
  };

  const onPress = (e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      nextSlide();
      return;
    }
    if (e.key === 'ArrowLeft') {
      prevSlide();
      return;
    }
  };
  useKeys(window, onPress);
  useKeys(newWindow, onPress);

  const openWindow = () => {
    const newWindow = window.open(
      '',
      presentation.title,
      'width=600,height=400',
    );
    if (!newWindow) return null;
    newWindow.onbeforeunload = () => {
      setNewWindow(null);
    };
    newWindow.document.title = presentation.title;
    const icon = newWindow.document.createElement('link');
    icon.href = favicon;
    icon.rel = 'icon';
    newWindow.document.head.appendChild(icon);
    newWindow.document.body.style.padding = '0';
    newWindow.document.body.style.margin = '0';
    setNewWindow(newWindow);
  };
  const closeWindow = () => {
    newWindow && newWindow.close();
  };
  useEffect(() => {
    if (!currentSlide) {
      setCurrentSlideImage(presentation.slides[0]);
    }
  });

  return (
    <div className="flex h-fit w-full flex-col gap-4 p-4 lg:w-1/2">
      <div className=" flex h-20 flex-row items-center px-10">
        <h2 className="text-md text-left font-semibold text-gray-800  md:text-xl lg:text-2xl">
          Live
        </h2>
      </div>
      {currentSlide && (
        <CarouselSlides
          selectedSlide={currentSlide}
          onSelectSlide={(slide) => setCurrentSlideImage(slide)}
          presentationSlides={presentation.slides}
        />
      )}
      <div className="flex w-full flex-row  justify-between gap-1 px-10">
        <div className="flex flex-row items-center justify-center gap-2">
          <Button
            end
            icon={<ClockIcon className="h-5 w-5 -translate-y-px"></ClockIcon>}
            className={
              isPlaying
                ? 'rounded-lg bg-red-500 p-1 text-white hover:bg-red-700'
                : 'rounded-lg  bg-tertiary p-1  text-primary hover:bg-primary hover:text-white'
            }
            onClick={() => (isPlaying ? stop() : start())}
          >
            {isPlaying ? 'Stop' : 'Start'}
          </Button>
          <Input
            className="w-20"
            type="number"
            step={1}
            min={0}
            defaultValue={5}
            onChange={handleSlideDurationTimeChange}
          />
        </div>
        <Button
          className={
            newWindow
              ? 'rounded-lg bg-red-500 p-1 text-white hover:bg-red-700'
              : 'rounded-lg  bg-tertiary p-1  text-primary hover:bg-primary hover:text-white'
          }
          onClick={() => (newWindow ? closeWindow() : openWindow())}
        >
          {newWindow ? 'Close' : 'Present'}
        </Button>
      </div>
      <div>
        {newWindow &&
          ReactDOM.createPortal(
            <WindowPresentation
              currentSlide={currentSlide as ISlide}
              slides={presentation.slides}
            />,
            newWindow.document.body,
          )}
      </div>
    </div>
  );
};

export default PresentationPreview;
