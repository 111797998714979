import React, { createContext, useEffect, useState } from 'react';
import { ITag } from '../../models';

import { API } from '../../services/url';
import { useUserServices } from '../../hooks';

interface ITagContext {
  contextTags: ITag[] | null;
  setContextTags: (Tags: ITag[]) => void;
  getTags: (query?: TagQuery) => Promise<ITag[]>;
}

const TagContext = createContext({} as ITagContext);

interface TagQuery {
  search?: string;
}

function TagProvider({ children }: { children: React.ReactNode }) {
  const { getAccessToken, session } = useUserServices();
  const [contextTags, setContext] = useState<ITag[] | null>(null);

  const setContextTags = (newTags: ITag[]) => {
    setContext(newTags);
  };

  const getTags = async (query?: TagQuery): Promise<ITag[]> => {
    if (query?.search) {
      const response = await fetch(`${API}tags?name=${query.search}`, {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
      });
      const data = await response.json();
      return data;
    }
    const response = await fetch(`${API}tags`, {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    if (contextTags !== null) return;
    getAccessToken().then((token) => {
      fetch(`${API}tags`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((data) => setContext(data as unknown as ITag[]));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextTags, session?.accessToken]);

  return (
    <TagContext.Provider
      value={{
        contextTags,
        getTags,
        setContextTags,
      }}
    >
      {children}
    </TagContext.Provider>
  );
}

export { TagProvider, TagContext };
