import { useState } from 'react';
import { DownArrowIcon } from '../../../components';
import ISlide from '../../../models/presentationImage.model';

type Props = {
  slides: ISlide[];
  className: string;
};

const SlidesPreview = ({ slides, className = 'h-72' }: Props) => {
  const [selectedSlide, setSelectedSlide] = useState(slides[0]);

  const changeSlideSelection = (jumpPosition: number) => {
    const newSelectionIndex = slides.indexOf(selectedSlide) + jumpPosition;
    if (!slides[newSelectionIndex]) return;
    setSelectedSlide(slides[newSelectionIndex]);
  };

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <button
        data-testid="left-arrow"
        onClick={() => changeSlideSelection(-1)}
        className="flex w-10 flex-col items-center justify-center p-4 hover:bg-gray-200 "
      >
        <DownArrowIcon className=" -right-4  z-30 h-10 w-10 rotate-90  fill-black stroke-none " />
      </button>
      <div>
        <img
          className={`${className} w-full object-cover`}
          src={selectedSlide.image.imgUrl}
          alt="current-slide"
        />
      </div>
      <button
        data-testid="right-arrow"
        onClick={() => changeSlideSelection(1)}
        className="flex w-10 flex-col items-center justify-center p-4 hover:bg-gray-200 "
      >
        <DownArrowIcon className=" -right-4  z-30 h-10 w-10 -rotate-90  fill-black stroke-none " />
      </button>
    </div>
  );
};

export default SlidesPreview;
