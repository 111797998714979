import { useState, FormEvent } from 'react';
import useOrderable from '../../hooks/useOrderable';
import { IImage } from '../../models';
import { Button } from '../Button';
import { Input } from '../Input';
import DraggableWrapper from '../utility/DraggableWrapper';

import PreviewCard from './PreviewCard';
import Alert from '../../services/alerts/AlertService';
import ISlide from '../../models/presentationImage.model';
import { useSelectableElements } from '../../hooks';

type Props = {
  images: IImage[];
  presentationTitle?: string;
  onSave: (title: string, slides: ISlide[]) => void;
  close: () => void;
};

const PresentationCreation = ({
  images,
  close,
  onSave,
  presentationTitle = '',
}: Props) => {
  const [title, setTitle] = useState(presentationTitle);
  const [previewCards, setPreviewCards] = useState(images);
  const [selectedItems, selectItem, clearSelection] = useSelectableElements(1);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value);
  };

  const {
    handleOrderableDrag,
    handleOrderableDrop,
    handleOrderableTouch,
    touched,
    elements: cards,
  } = useOrderable(previewCards);

  const handleSave = () => {
    if (title.trim() === '')
      return Alert.info({ title: 'Title input is empty' });

    const slides: ISlide[] = (cards as IImage[]).map((image) => {
      return { image, notes: '' } as ISlide;
    });

    onSave(title, slides);
  };
  const handleDelete = (image: IImage) => {
    if (touched === image) handleOrderableTouch(image);
    const updatedCards = [...cards];
    const index = cards.indexOf(image);
    updatedCards.splice(index, 1);
    setPreviewCards(updatedCards as IImage[]);
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex  w-full flex-col gap-6 rounded-md  bg-white p-5 text-left sm:w-3/4 md:p-10  lg:w-1/2"
    >
      <h2 className=" text-md font-semibold text-gray-800  md:text-xl lg:text-2xl">
        Edit presentation
      </h2>
      <div className="flex flex-row items-center justify-start gap-2">
        <label
          className="text-md font-semibold  text-gray-800 lg:text-2xl"
          htmlFor="presentation-title"
        >
          Title
        </label>
        <Input
          data-cy="presentation-title-input"
          type="text"
          placeholder={'Presentation title'}
          value={title}
          onChange={handleChange}
          aria-label={'presentation-title'}
          name="presentation-title"
        ></Input>
      </div>
      <div className="box-border w-full overflow-y-auto overflow-x-hidden">
        <div
          data-cy="presentation-preview-cards-container"
          className="grid max-h-96 w-full grid-cols-3 gap-4 p-4   sm:grid-cols-4  md:grid-cols-5 "
        >
          {(cards as IImage[]).map((item: IImage, i: number) => {
            return (
              <DraggableWrapper
                key={i}
                onDrop={() => handleOrderableDrop(item)}
                onDrag={() => handleOrderableDrag(item)}
                onTouch={() => handleOrderableTouch(item)}
                touchedStyle={touched === item ? 'opacity-50' : ''}
              >
                <PreviewCard
                  style={{ aspectRatio: '1/1' }}
                  className="h-full w-full cursor-pointer"
                  selected={selectedItems.includes(item)}
                  onClick={() =>
                    selectedItems.includes(item)
                      ? selectItem(item)
                      : clearSelection([item])
                  }
                  img={item.imgUrl}
                  alt="new slide"
                  onDelete={() => handleDelete(item)}
                ></PreviewCard>
              </DraggableWrapper>
            );
          })}
        </div>
      </div>
      <div className="flex w-full flex-row justify-end gap-5">
        <Button
          className="rounded-lg bg-tertiary text-primary hover:bg-primary hover:text-white"
          onClick={close}
        >
          Discard
        </Button>
        <Button
          data-cy="save-presentation-button"
          className="rounded-lg bg-secondary text-white hover:bg-primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default PresentationCreation;
