import { useEffect } from 'react';

const useKeys = (
  window: Window | null,
  onPressKey: (e: KeyboardEvent) => void,
) => {
  useEffect(() => {
    if (!window) return;
    const handlePress = (e: KeyboardEvent) => {
      onPressKey(e);
    };
    window.addEventListener('keydown', handlePress);
    return () => window.removeEventListener('keydown', handlePress);
  });
};
export default useKeys;
