import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { loginFormValidationSchema } from './login.schema';
import { useUserServices } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Alert from '../../services/alerts/AlertService';
import Loader from '../../components/Loader/Loader';
import WelcomeToArchinav from '../SignUp/WelcomeToArchinav';

interface IFormLogin {
  email: string;
  password: string;
}
const initialValues: IFormLogin = {
  email: '',
  password: '',
};

export default function Login() {
  const [hidePassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { loginUser } = useUserServices();

  const handleOnSubmit = (values: IFormLogin) => {
    setLoading(true);
    loginUser(values.email, values.password)
      .then((user) => {
        Alert.success({ message: `Welcome ${user?.name}` });
        navigate('/collection');
      })
      .catch((err) => {
        Alert.error({ message: err.message, title: err.name });
      })
      .finally(() => setLoading(false));
  };

  const handleShowPassword = () =>
    setShowPassword((prevHidePassword) => !prevHidePassword);

  const renderError = (message: string) => (
    <span aria-label="error" className="text-xs text-red-600">
      {message}
    </span>
  );

  return (
    <Page className="bg-white">
      <div className=" flex h-full w-full flex-grow flex-col  justify-center  p-4 md:w-3/4  lg:w-1/2">
        <div className="flex min-h-full flex-col justify-center  sm:px-6 lg:px-8">
          <WelcomeToArchinav></WelcomeToArchinav>
          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
            {loading && (
              <div className="flex h-48 w-full flex-col items-center justify-center">
                <Loader className="h-16 w-16"></Loader>
              </div>
            )}
            {!loading && (
              <div className="bg-white py-8 px-4 shadow-lg sm:px-10">
                <Formik
                  initialValues={initialValues}
                  validationSchema={loginFormValidationSchema}
                  onSubmit={(values) => {
                    handleOnSubmit(values);
                  }}
                >
                  <Form className="space-y-6">
                    <div>
                      <div className="mt-2">
                        <Field
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          placeholder="Email"
                          className="block w-full rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                        />
                        <ErrorMessage
                          className="text-sm text-red-600 "
                          name="email"
                          render={renderError}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="relative mt-2">
                        <span className="absolute right-2 top-2">
                          <label className="relative mb-5 inline-flex cursor-pointer items-center">
                            <input
                              type="checkbox"
                              aria-label="toggle-show-password"
                              className="peer sr-only"
                              onChange={handleShowPassword}
                              defaultChecked={!hidePassword}
                            />
                            <div className="peer h-5 w-9 rounded-full bg-gray-300 after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-secondary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none"></div>
                          </label>
                        </span>
                        <Field
                          id="password"
                          name="password"
                          type={hidePassword ? 'password' : 'text'}
                          placeholder="Password"
                          autoComplete="current-password"
                          className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                        />

                        <ErrorMessage
                          className="text-sm text-red-600"
                          name="password"
                          render={renderError}
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-full bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm transition-all duration-150 ease-in-out hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      >
                        Login
                      </button>
                    </div>
                  </Form>
                </Formik>

                <div className="relative mt-2">
                  <div className="relative flex justify-center text-base">
                    <span className=" bg-white px-2 text-gray-500">
                      <span
                        data-cy="password-recovery-link"
                        onClick={() => navigate('/password-recovery')}
                        className="cursor-pointer font-medium text-indigo-600 transition-all duration-75 ease-in-out hover:border-b-2 hover:border-indigo-400 hover:text-indigo-500"
                      >
                        Forgot password?
                      </span>
                    </span>
                  </div>
                </div>

                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center py-3 text-sm">
                    <span className="bg-white px-2 text-gray-500">
                      You don't have an account?
                    </span>
                  </div>
                </div>

                <div className="relative">
                  <div className="relative flex justify-center text-base">
                    <span className=" bg-white px-2 text-gray-500">
                      <span
                        onClick={() => navigate('/signup')}
                        className="cursor-pointer font-medium text-indigo-600 transition-all duration-75 ease-in-out hover:border-b-2 hover:border-indigo-400 hover:text-indigo-500"
                      >
                        Sign up
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}
