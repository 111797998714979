type Props = {
  hover: boolean;
  onClick?: () => void;
  className?: string;
  ariaLabel?: string;
  dataCy?: string;
  children: JSX.Element | JSX.Element[] | string;
};

const HoverButton = ({
  hover,
  onClick = undefined,
  children = '',
  className = '',
  ariaLabel = '',
  dataCy = '',
}: Props) => {
  const handleClick = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.TouchEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    onClick && onClick();
  };

  return (
    <button
      data-cy={dataCy}
      aria-label={ariaLabel}
      onClick={(e) => handleClick(e)}
      className={`${
        onClick && hover ? 'visible' : 'invisible'
      }  ${className} absolute z-20 flex flex-col items-center  justify-center rounded-full`}
    >
      {children}
    </button>
  );
};

export default HoverButton;
