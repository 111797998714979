const Footer = () => {
  return (
    <footer className="flex h-16 flex-col items-center justify-center bg-primary">
      <a
        className="font-bold text-gray-300"
        href="https://www.kickstarter.com/projects/archinav/archinav-your-image-navigating-platform"
        target="_blank"
        rel="noreferrer"
      >
        Click here to support Archinav
      </a>
    </footer>
  );
};

export default Footer;
