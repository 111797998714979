import { Button } from '../Button';

type Props = {
  total: number;
  processed: number;
  onContinue?: () => void;
  loadingText?: string;
  failText?: string;
  completedText?: string;
  containerColor?: string;
  fillColor?: string;
};

const ProgressBar = ({
  total,
  processed,
  onContinue,
  failText = '',
  loadingText = 'Loading...',
  completedText = 'Completed',
  containerColor = 'bg-gray-200',
  fillColor = 'bg-secondary ',
}: Props) => {
  const full = processed === total;
  return (
    <div className="flex h-52 w-full flex-col items-center justify-center gap-6">
      <h2 className="text-lg font-bold text-gray-800">
        {failText ? failText : full ? completedText : loadingText}
      </h2>

      <div className={`h-2.5 w-full rounded-full   ${containerColor}`}>
        <div
          className={`h-2.5 ${!full && 'animate-pulse'} rounded-full ${
            failText ? 'animate-none bg-red-500' : ''
          } ${fillColor}`}
          style={{ width: `${(processed / total) * 100}%` }}
        ></div>
      </div>
      <Button
        className={
          (full && onContinue) || failText
            ? ' rounded-lg bg-secondary hover:bg-primary'
            : ' invisible rounded-lg bg-secondary hover:bg-primary'
        }
        onClick={onContinue}
      >
        {onContinue ? 'Continue' : ''}
      </Button>
    </div>
  );
};

export default ProgressBar;
