import { useEffect, useState } from 'react';
import { SlidesProvider } from '../../components/contexts/SlidesProvider';
import Page from '../../components/Page/Page';
import usePresentation from '../../hooks/usePresentations';
import PresentationEdit from './components/PresentationEdit';
import PresentationPreview from './components/PresentationPreview';
import { useParams } from 'react-router-dom';
import IPresentation from '../../models/presentation.model';
import Loader from '../../components/Loader/Loader';

const Presentation = () => {
  const { getPresentationById } = usePresentation();
  const [currentPresentation, setCurrentPresentation] =
    useState<IPresentation | null>(null);
  const { presentationId } = useParams();

  useEffect(() => {
    if (!presentationId) return;
    getPresentationById(+presentationId).then((data) =>
      setCurrentPresentation(data),
    );
  }, [getPresentationById, presentationId]);

  return (
    <Page>
      {currentPresentation ? (
        <div className="flex h-full w-full flex-grow flex-col flex-wrap p-4  sm:flex-row md:w-3/4">
          <SlidesProvider>
            <PresentationEdit presentation={currentPresentation} />
            <PresentationPreview presentation={currentPresentation} />
          </SlidesProvider>
        </div>
      ) : (
        <div className=" flex h-full w-full flex-grow flex-col items-center justify-center">
          <Loader className="h-20 w-20"></Loader>
        </div>
      )}
    </Page>
  );
};

export default Presentation;
