import { useEffect, useState } from 'react';

type ReturnType = {
  handleOrderableDrag: (orderableElement: unknown) => void;
  handleOrderableDrop: (orderableElement: unknown) => void;
  handleOrderableTouch: (orderableElement: unknown) => unknown;
  touched: unknown;
  elements: unknown[];
};

const useOrderable = (orderableElements: unknown[]): ReturnType => {
  const [elements, setElements] = useState<unknown[]>(orderableElements);
  const [dragged, setDragged] = useState<unknown>(null);
  const [touched, setTouched] = useState<unknown>(null);

  const handleOrderableDrop = (item: unknown) => {
    setDragged(null);
    if (!dragged) return;
    const newOrder = [...elements];
    const draggedItemIndex = elements.indexOf(dragged);
    const targetItemIndex = elements.indexOf(item);
    newOrder.splice(draggedItemIndex, 1);
    newOrder.splice(targetItemIndex, 0, dragged);
    setElements(newOrder);
  };

  const handleOrderableDrag = (item: unknown) => {
    setDragged(item);
  };

  const handleOrderableTouch = (item: unknown) => {
    const newOrder = [...elements];
    if (touched === item) {
      setTouched(null);
    } else if (touched) {
      const touchedItemIndex = elements.indexOf(touched);
      const targetItemIndex = elements.indexOf(item);
      newOrder.splice(touchedItemIndex, 1);
      newOrder.splice(targetItemIndex, 0, touched);
      setElements(newOrder);
      setTouched(null);
    } else {
      setTouched(item);
    }
    return touched;
  };
  useEffect(() => {
    setElements(orderableElements);
  }, [orderableElements]);

  return {
    handleOrderableDrag,
    handleOrderableDrop,
    handleOrderableTouch,
    touched,
    elements,
  };
};

export default useOrderable;
