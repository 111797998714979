import { useState, useEffect, useRef } from 'react';

const useHover = (): [boolean, React.MutableRefObject<HTMLElement | null>] => {
  const [hovered, setHovered] = useState(false);
  const [swipe, setSwipe] = useState(false);
  const ref = useRef<HTMLElement>(null);

  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);
  const handleTouchMove = () => setSwipe(true);
  const handleTouchEnd = () => {
    if (swipe) {
      setSwipe(false);
      return;
    }
    setHovered(!hovered);
  };
  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      node.addEventListener('touchend', handleTouchEnd);
      node.addEventListener('touchmove', handleTouchMove);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
        node.removeEventListener('touchend', handleTouchEnd);
        node.addEventListener('touchmove', handleTouchMove);
      };
    }
  });

  return [hovered, ref];
};
export { useHover };
