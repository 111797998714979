import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

const loginWithCredentials = (
  email: string,
  password: string,
  userPool: CognitoUserPool,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const authenticationData = {
      Username: email,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result: CognitoUserSession) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

const refreshMock = () => {
  const mockedSession: CognitoUserSession = {
    getIdToken: function (): CognitoIdToken {
      return new CognitoIdToken({ IdToken: 'test-id-token' });
    },
    getRefreshToken: function (): CognitoRefreshToken {
      return new CognitoRefreshToken({ RefreshToken: 'test-refresh-token' });
    },
    getAccessToken: function (): CognitoAccessToken {
      return new CognitoAccessToken({ AccessToken: 'test-token' });
    },
    isValid: function (): boolean {
      return true;
    },
  };
  return Promise.resolve(mockedSession);
};

const refreshUserToken = async (
  userPool: CognitoUserPool,
): Promise<CognitoUserSession> => {
  if ((window as any).Cypress) {
    if ((window as any).refreshSession) return refreshMock();
  }
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser === null) return reject('no refresh token');
    cognitoUser.getSession(function (
      err: Error,
      session: CognitoUserSession | null,
    ) {
      if (err) {
        reject(err);
        return;
      }
      resolve(session as CognitoUserSession);
    });
  });
};

const signOut = async (userPool: CognitoUserPool) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) return;
    cognitoUser?.signOut();
    resolve(null);
  });
};

const passwordRecovery = async (
  cognitoUserPool: CognitoUserPool,
  email: string,
) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoUserPool,
  });
  if ((window as any).Cypress) {
    return Promise.resolve();
  }
  return new Promise<void>((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        resolve(data);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

const confirmNewPassword = async (
  cognitoUserPool: CognitoUserPool,
  {
    newPassword,
    code,
    email,
  }: { newPassword: string; code: string; email: string },
): Promise<void> => {
  if ((window as any).Cypress) {
    return Promise.resolve();
  }

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoUserPool,
  });
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        resolve();
      },
      onFailure(err) {
        reject(err);
      },
    });
  });
};

export {
  loginWithCredentials,
  refreshUserToken,
  signOut,
  passwordRecovery,
  confirmNewPassword,
};
