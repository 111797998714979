import HoverButton from '../../../components/HoverButton/HoverButton';
import PlusIcon from '../../../components/Icons/PlusIcon';
import useClickAndDoubleClick from '../../../hooks/useClickAndDoubleClick';

type Props = {
  img: string;
  name: string;
  className: string;
  onSeeCollection?: () => void;
  onAddImages?: () => void;
  onSelect?: () => void;
  style?: React.CSSProperties;
  selected?: boolean;
};

const CollectionCard = ({
  img,
  name,
  onSeeCollection = undefined,
  onAddImages = undefined,
  onSelect = undefined,
  className = '',
  style = undefined,
  selected = false,
}: Props) => {
  const handleClickAndDoubleClick = useClickAndDoubleClick(
    onSelect,
    onSeeCollection,
  );
  return (
    <div
      style={style}
      onClick={handleClickAndDoubleClick}
      className={`${className} ${
        selected ? 'border-4 border-solid border-tertiary' : ''
      } relative flex  cursor-pointer flex-col items-center justify-center bg-white p-2 shadow-lg `}
    >
      <HoverButton
        dataCy="card-plus-button"
        ariaLabel="add-images-button"
        hover={selected}
        onClick={onAddImages}
        className={'bottom-6 -left-4  h-10 w-10  bg-primary hover:bg-secondary'}
      >
        <PlusIcon className="fill-white"></PlusIcon>
      </HoverButton>
      {!img && (
        <div
          className={
            'flex h-full w-full flex-col items-center justify-center bg-slate-200'
          }
        >
          <h2 className="font-bold text-gray-700">Empty</h2>
        </div>
      )}
      {img && (
        <img
          className={'h-full w-full overflow-hidden object-cover '}
          src={img}
          alt="collection card"
        />
      )}
      <div className="flex w-full flex-row flex-wrap">
        <h2 className=" mt-4 break-all text-left text-xs font-bold text-gray-800">
          {name}
        </h2>
      </div>
    </div>
  );
};
export default CollectionCard;
