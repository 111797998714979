import * as Yup from 'yup';

const specialCharacterRegex = /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`´{|}~]/;

export const signUpFormValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('This field should be a valid email')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must contain 8 or more characters')
    .max(99, 'Password sould be shorter than 99 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      specialCharacterRegex,
      'Password must contain at least one special character',
    )

    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Password confirmation is required'),
});
