import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import archinavLogo from '../../assets/archinavLogo.svg';

import { useNavigate } from 'react-router-dom';
import { useUserServices } from '../../hooks';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const { session, userSignOut } = useUserServices();
  const navigate = useNavigate();
  const navigation = session
    ? [
        {
          name: 'Collections',
          href: '#',
          current: false,
          route: '/collection',
        },
        {
          name: 'Presentations',
          href: '#',
          current: false,
          route: '/presentation',
        },
        {
          name: 'Sign Out',
          href: '#',
          current: false,
          route: '/',
        },
      ]
    : [
        { name: 'Signup', href: '#', current: false, route: '/signup' },
        { name: 'Login', href: '#', current: false, route: '/signin' },
      ];

  return (
    <Disclosure as="nav" className="bg-primary">
      {({ open }: any) => (
        <>
          <div className=" flex w-full flex-col items-center justify-center ">
            <div className="relative flex h-16 w-full items-center justify-between  p-4 md:w-3/4">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="ml-4 inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-secondary hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="relative block h-32 w-auto sm:-left-11"
                    src={archinavLogo}
                    alt="Your Company"
                  />{' '}
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <button
                        data-cy={`${item.name}-nav-button`}
                        key={item.name}
                        onClick={() => {
                          item.name === 'Sign Out'
                            ? userSignOut().then(() => navigate('/signin'))
                            : navigate(item.route);
                        }}
                        className={classNames(
                          item.current
                            ? ' border border-gray-300 px-3 py-2 text-white hover:text-white'
                            : ' border border-gray-300 text-gray-300 hover:text-white',
                          'rounded-md  px-3 py-2 text-sm font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* MOBILE MENU */}
          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="button"
                  onClickCapture={() => {
                    item.name === 'Sign Out'
                      ? userSignOut().then(() => navigate('/signin'))
                      : navigate(item.route);
                  }}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-secondary hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
