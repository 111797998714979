import { useEffect } from 'react';

const useWheel = (
  window: Window | null,
  onWheelEvent: (e: WheelEvent) => void,
) => {
  useEffect(() => {
    if (!window) return;
    const handleWheel = (e: WheelEvent) => {
      onWheelEvent(e);
    };
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => window.removeEventListener('wheel', handleWheel);
  });
};
export default useWheel;
