import ISlide from '../../../models/presentationImage.model';

type Props = {
  slides: ISlide[];
  currentSlide: ISlide;
};

const WindowPresentation = ({ slides, currentSlide }: Props) => {
  return (
    <div
      style={{
        overflow: 'hidden',
        backgroundColor: 'black',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'fixed',
          opacity: '0',
          height: '100vh',
          width: '100vw',
        }}
      ></div>
      {slides.map((slide: ISlide, i: number) => (
        <img
          key={`${i}`}
          style={{
            display: slide === currentSlide ? 'block' : 'none',
            objectFit: 'contain',
            height: '100%',
            width: '100%',
          }}
          src={currentSlide.image.imgUrl}
          alt="play screen"
        />
      ))}
    </div>
  );
};
export default WindowPresentation;
