import { ChangeEvent, useRef, useState } from 'react';
import { Button } from '../Button';

type Props = {
  handleFiles: (files: File[]) => void;
  dragOverStyle?: string;
  children?: React.ReactNode | React.ReactNode[];
  className: string;
  style?: React.HTMLAttributes<HTMLDivElement>;
};

const DropFileArea = ({
  handleFiles,
  dragOverStyle = 'bg-blue-400',
  children,
  className = '',
  style,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isDragging, setIsDragging] = useState(false);

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleInputFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const files = Array.from(e.target.files);
    handleFiles(files);
  };

  return (
    <div
      data-cy="drop-zone"
      style={style && style}
      className={`${children ? '' : ' py-20'} ${className} ${
        isDragging ? dragOverStyle : ''
      }`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      <>
        <input
          data-testid="file-input"
          type="file"
          hidden
          aria-hidden
          multiple
          accept="image/*"
          onChange={handleInputFiles}
          ref={inputRef}
        />
        {children ? (
          <>{children}</>
        ) : (
          <>
            <span className="font-bold">Drag and drop images</span>
            <p>or</p>
            <Button
              className=" rounded-lg bg-secondary text-white hover:bg-primary "
              onClick={handleButtonClick}
            >
              Explore
            </Button>
            <p className=" mt-2 text-xs text-gray-700">
              jpeg, png, tiff, webp, svg.
            </p>
          </>
        )}
      </>
    </div>
  );
};

export default DropFileArea;
