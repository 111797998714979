import { createContext, useContext, useState } from 'react';
import ISlide from '../../models/presentationImage.model';
import { useUserServices } from '../../hooks';
import { API } from '../../services/url';

interface ISlideContext {
  currentSlide: ISlide | null;
  setCurrentSlideImage: (image: ISlide) => void;
  updateSlide: (presentationId: number, updatedSlide: ISlide) => Promise<void>;
}

const SlidesContext = createContext({} as ISlideContext);

const useSlides = () => {
  return useContext(SlidesContext);
};

const SlidesProvider = ({ children }: { children: React.ReactNode }) => {
  const { getAccessToken } = useUserServices();
  const [currentSlide, setCurrentSlide] = useState<ISlide | null>(null);
  const setCurrentSlideImage = (image: ISlide) => {
    setCurrentSlide(image);
  };

  const updateSlide = async (presentationId: number, updatedSlide: ISlide) => {
    const response = await fetch(
      `${API}presentations/${presentationId}/slides/${updatedSlide.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${await getAccessToken()}`,
        },
        body: JSON.stringify(updatedSlide),
      },
    );
    if (!response.ok) throw Error('Failed to update slide');
  };
  return (
    <SlidesContext.Provider
      value={{ currentSlide, setCurrentSlideImage, updateSlide }}
    >
      {children}
    </SlidesContext.Provider>
  );
};

export { SlidesProvider, useSlides };
