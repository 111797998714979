import { TextAreaProps } from './types';

export default function TextArea({
  className,
  ...textAreaProps
}: TextAreaProps) {
  return (
    <textarea
      className={`border border-gray-300 bg-gray-50 p-2 text-sm  text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 ${
        className ? className : ''
      }`}
      {...textAreaProps}
    ></textarea>
  );
}
