export const imageAsUrlProcessing = (
  iterableFiles: File[],
  onLoad?: () => void,
) => {
  const reader = new FileReader();

  const imagesAsUrl: unknown[] = [];

  return new Promise((resolve, reject) => {
    let currentIndex = 0;

    const processNextFile = () => {
      if (currentIndex >= iterableFiles.length) {
        resolve(imagesAsUrl);
        return;
      }

      const currentFile = iterableFiles[currentIndex];

      reader.onload = (e) => {
        imagesAsUrl.push(e.target?.result);
        currentIndex++;
        onLoad && onLoad();
        processNextFile();
      };
      reader.readAsDataURL(currentFile);
    };

    processNextFile();
  });
};
