import { FormEvent, useState } from 'react';
import { useCollection, useSelectableElements } from '../../hooks';
import { ICollection } from '../../models';

import { imageAsUrlProcessing } from '../../utilities/image.processor';
import { Button } from '../Button';
import { Input } from '../Input';
import PreviewCard from '../PresentationCreation/PreviewCard';
import ProgressBar from '../ProgressBar/ProgressBar';
import DropFileArea from '../utility/DropFileArea';
import Alert from '../../services/alerts/AlertService';
import TrashIcon from '../Icons/TrashIcon';

// Used for mocking promises resolving one by one
/*
function delay(file: File): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    setTimeout(() => {
      //reject('fail to upload file');
      resolve(1);
    }, 2000);
  });
}
*/
type Props = {
  close: () => void;
  collection?: ICollection;
  canDelete?: boolean;
};

const CollectionCreation = ({
  close,
  collection = undefined,
  canDelete = false,
}: Props) => {
  const [imagesAsUrls, setImagesAsUrls] = useState<Blob[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [uploadingImages, setUploadingImages] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(
    collection ? collection.title : '',
  );
  const [filesProcessed, setFilesProcessed] = useState<number>(0);
  const {
    collections,
    createCollection,
    addImage,
    getCollectionById,
    deleteCollection,
    setCollections,
    refresh,
  } = useCollection();
  const handleChange = (e: FormEvent<HTMLInputElement>): void => {
    setTitle(e.currentTarget.value);
  };

  const [selectedItems, selectItem, clearSelection] = useSelectableElements(1);

  const handleSave = async () => {
    if (imageFiles.length === 0) return;
    if (title.trim() === '' && !collection) {
      return Alert.info({ title: 'Title input is empty!!!' });
    }

    const failedUploads = [];
    const isNewCollection = !collection;
    let collectionId;

    try {
      collectionId = isNewCollection
        ? (await createCollection(title)).id
        : collection.id;
    } catch (error) {
      return Alert.error({ message: (error as Error).message });
    }
    setUploadingImages(true);
    for (const file of imageFiles) {
      try {
        await addImage(collectionId, file);
        setFilesProcessed((prev) => prev + 1);
      } catch (err) {
        failedUploads.push(file.name);
        setFilesProcessed((prev) => prev + 1);
      }
    }

    if (failedUploads.length !== 0) {
      const alertMsg = `${failedUploads.join('\n-')}`;
      Alert.error({
        title: 'These files did not upload successfully',
        message: alertMsg,
      });
      return;
    }

    if (isNewCollection) {
      const createdCollection = await getCollectionById(collectionId);
      setCollections([createdCollection, ...(collections as ICollection[])]);
    } else {
      refresh();
    }
  };

  const handleFiles = (files: File[]) => {
    if (!files || files.length === 0) return;
    setImageFiles([...imageFiles, ...files]);

    imageAsUrlProcessing(files).then((blobs) => {
      setImagesAsUrls([...imagesAsUrls, ...(blobs as Blob[])]);
    });
  };

  const deletePreview = (index: number) => {
    setImagesAsUrls((prev) => {
      const newArr = [...prev];
      newArr.splice(index, 1);
      return newArr;
    });
    setImageFiles((prev) => {
      const newArr = [...prev];
      newArr.splice(index, 1);
      return newArr;
    });
  };

  if (uploadingImages) {
    if (filesProcessed === imageFiles.length) {
      close();
    }
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex w-96 flex-col gap-6 rounded-md  bg-white p-5 text-left sm:w-3/4 md:p-10  lg:w-1/2"
      >
        <div className="w-full  px-12">
          <ProgressBar
            loadingText="Uploading images..."
            processed={filesProcessed ? filesProcessed : 0}
            total={imageFiles.length}
          />
        </div>
      </div>
    );
  }

  const handleDeleteCollection = async (col: ICollection) => {
    if (!collection) return;
    if (!collections) return;
    const onConfirm = () => {
      deleteCollection(col.id)
        .then(() => {
          close();
          setCollections(
            [...collections].filter(
              (element: ICollection) => col.id !== element.id,
            ),
          );
        })
        .catch((err) => Alert.error({ title: err.name, message: err.message }));
    };

    Alert.confirm(
      { title: 'Delete collection:', message: col.title },
      onConfirm,
    );
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="flex w-96 flex-col gap-6 rounded-md  bg-white p-5 text-left sm:w-3/4 md:p-10  lg:w-1/2"
    >
      <h2 className=" text-md font-semibold text-gray-800  md:text-xl lg:text-2xl">
        {collection
          ? `Add images to ${collection.title} collection`
          : 'Create collection'}
      </h2>
      {!collection && (
        <div className="flex flex-row items-center justify-start gap-2">
          <label
            className="text-md font-semibold  text-gray-800 lg:text-2xl"
            htmlFor="collection-title"
          >
            Title
          </label>
          <Input
            data-cy="collection-title-input"
            type="text"
            placeholder={'Collection title'}
            value={title}
            onChange={handleChange}
            aria-label={'collection-title'}
            name="collection-title"
            className={`${title === '' ? 'border-red-500' : ''}`}
          ></Input>
        </div>
      )}
      <div className=" h-fit  max-h-96 w-full  overflow-y-auto">
        <DropFileArea
          className="flex h-full  w-full flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-500 bg-white"
          handleFiles={handleFiles}
        >
          {imagesAsUrls.length !== 0 && (
            <div className="grid  h-full w-full grid-cols-3 gap-4 p-4 sm:grid-cols-4 md:grid-cols-5">
              {imagesAsUrls.map((img: Blob, i: number) => (
                <PreviewCard
                  style={{ aspectRatio: '1/1' }}
                  selected={selectedItems.includes(i)}
                  onClick={() =>
                    selectedItems.includes(i)
                      ? selectItem(i)
                      : clearSelection([i])
                  }
                  key={i}
                  img={img}
                  alt={'preview alt'}
                  className="w-full cursor-pointer"
                  onDelete={() => {
                    clearSelection();
                    deletePreview(i);
                  }}
                ></PreviewCard>
              ))}
            </div>
          )}
        </DropFileArea>
      </div>
      <div>
        <div className="flex w-full flex-row justify-between">
          {canDelete && (
            <button
              data-cy="delete-collection-button"
              className={
                'rounded-lg bg-tertiary p-2 text-primary hover:bg-primary hover:text-white'
              }
              onClick={() => collection && handleDeleteCollection(collection)}
            >
              <TrashIcon className="h-6 w-6"></TrashIcon>
            </button>
          )}
          <div className="flex w-full flex-row justify-end gap-5">
            <Button
              className="rounded-lg bg-tertiary text-primary hover:bg-primary hover:text-white "
              onClick={close}
            >
              Discard
            </Button>
            <Button
              data-cy="submit-create-collection"
              className=" rounded-lg bg-secondary text-white hover:bg-primary "
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionCreation;
