import { LegacyRef, useRef, useState } from 'react';
import { DownArrowIcon } from '../../../components';
import ISlide from '../../../models/presentationImage.model';

type Props = {
  presentationSlides: ISlide[];
  onSelectSlide: (slide: ISlide) => void;
  selectedSlide: ISlide;
};

const CarouselSlides = ({
  presentationSlides,
  onSelectSlide: onSelectImage,
  selectedSlide,
}: Props) => {
  const [position, setPosition] = useState<number>(0);
  const firstRef = useRef<HTMLElement | null>(null);
  const secondRef = useRef<HTMLElement | null>(null);

  const handlePositions = () => {
    if (!firstRef.current || !secondRef.current) return;
    const rect1 = firstRef.current.getBoundingClientRect();
    const rect2 = secondRef.current.getBoundingClientRect();
    let isFirst = true;
    isFirst = rect1.right < rect2.left;
    return isFirst;
  };

  const scrollLeft = () => {
    setPosition(position - 120 > 0 ? position - 120 : 0);
  };
  const scrollRight = () => {
    if (handlePositions()) return;
    setPosition(position + 120);
  };

  const changeSlideSelection = (jumpPosition: number) => {
    const newSelectionIndex =
      presentationSlides.indexOf(selectedSlide) + jumpPosition;
    if (!presentationSlides[newSelectionIndex]) return;
    onSelectImage(presentationSlides[newSelectionIndex]);
  };

  return (
    <>
      <div className="flex w-full flex-row items-center justify-between">
        <button
          data-testid="left-arrow"
          ref={secondRef as LegacyRef<HTMLButtonElement>}
          onClick={() => changeSlideSelection(-1)}
          className="flex w-10 flex-col items-center justify-center p-4 hover:bg-gray-200 "
        >
          <DownArrowIcon className=" -right-4  z-30 h-10 w-10 rotate-90  fill-black stroke-none " />
        </button>
        <div className="w-full flex-grow bg-gray-200">
          <img
            className="h-72 w-full object-contain"
            src={selectedSlide.image.imgUrl}
            alt="current-slide"
          />
        </div>
        <button
          data-testid="right-arrow"
          ref={secondRef as LegacyRef<HTMLButtonElement>}
          onClick={() => changeSlideSelection(1)}
          className="flex w-10 flex-col items-center justify-center p-4 hover:bg-gray-200"
        >
          <DownArrowIcon className=" -right-4  z-30 h-10 w-10 -rotate-90  fill-black stroke-none" />
        </button>
      </div>

      <div className="flex w-full flex-row items-center justify-between">
        <button
          onClick={scrollLeft}
          className="ml-10 flex w-10 flex-col items-center justify-center p-4 hover:bg-gray-200 "
        >
          <DownArrowIcon className=" -left-4  z-30  h-6 w-6 rotate-90  fill-black stroke-none " />
        </button>

        <div className="w-full overflow-hidden">
          <div
            style={{ marginLeft: `-${position}px` }}
            className={
              'my-2 flex w-full items-center gap-4 transition-all duration-300 ease-out'
            }
          >
            {presentationSlides.map((slide: ISlide, i: number) => (
              <div
                ref={
                  presentationSlides[presentationSlides.length - 1] === slide
                    ? (firstRef as LegacyRef<HTMLDivElement>)
                    : null
                }
                onClick={() => onSelectImage(slide)}
                className={`flex h-20 max-h-20 w-20 flex-shrink-0 cursor-pointer flex-col justify-between bg-white p-2 transition-all ease-out  hover:brightness-75 ${
                  slide === selectedSlide ? 'brightness-50' : ''
                }`}
                key={i}
              >
                <img
                  src={slide.image.imgUrl}
                  alt="slide"
                  className={'h-4/5 object-cover'}
                />
                <span className="h-1/5 text-right  text-sm text-gray-800">
                  {i + 1}
                </span>
              </div>
            ))}
          </div>
        </div>
        <button
          ref={secondRef as LegacyRef<HTMLButtonElement>}
          onClick={scrollRight}
          className="mr-10 flex w-10 flex-col items-center justify-center p-4 hover:bg-gray-200 "
        >
          <DownArrowIcon className=" -right-4  z-30 h-6 w-6 -rotate-90  fill-black stroke-none " />
        </button>
      </div>
    </>
  );
};

export default CarouselSlides;
