import React, { useEffect, useState } from 'react';
import { Button } from '../../../components';
import { useSlides } from '../../../components/contexts/SlidesProvider';
import TextArea from '../../../components/TextArea/TextArea';
import IPresentation from '../../../models/presentation.model';
import ISlide from '../../../models/presentationImage.model';

import CarouselSlides from './CarouselSlides';
import Alert from '../../../services/alerts/AlertService';
import LockIcon from '../../../components/Icons/LockIcon';

type Props = {
  presentation: IPresentation;
};

const PresentationEdit = ({ presentation }: Props) => {
  const [isSync, setIsSync] = useState<boolean>(true);
  const { currentSlide, updateSlide } = useSlides();
  const [currentNotes, setCurrentNotes] = useState<string>('');
  const [editNotes, setEditNotes] = useState<boolean>(false);
  const [editingSlide, setEditingSlide] = useState<ISlide>(
    presentation.slides[0],
  );

  const handleChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setCurrentNotes(e.currentTarget.value);
  };

  const onSaveNotes = () => {
    if (!editingSlide) return;
    const updatedSlide = editingSlide;
    updatedSlide.notes = currentNotes;
    updateSlide(presentation.id, updatedSlide)
      .then(() => setEditNotes(false))
      .catch((err) =>
        Alert.error({ title: `${err.name}`, message: `${err.message}` }),
      );
  };

  useEffect(() => {
    if (!currentSlide) return;
    if (!isSync) return;
    setEditingSlide(currentSlide);
  }, [currentSlide, isSync]);

  useEffect(() => {
    setCurrentNotes(editingSlide.notes);
  }, [editingSlide]);

  return (
    <div className="flex h-fit w-full flex-col gap-4 p-4 lg:w-1/2">
      <div className=" flex h-20 flex-row items-center justify-between px-10">
        <h2 className="text-md text-left font-semibold text-gray-800  md:text-xl lg:text-2xl">
          {presentation.title}
        </h2>
        <button
          className={
            'rounded-lg p-2 text-primary hover:bg-primary hover:text-white'
          }
          onClick={() => setIsSync((prev) => !prev)}
        >
          <LockIcon closed={isSync} className="h-6 w-6"></LockIcon>
        </button>
      </div>
      <CarouselSlides
        presentationSlides={presentation.slides}
        selectedSlide={editingSlide}
        onSelectSlide={(slide) => {
          !isSync && setEditingSlide(slide);
        }}
      />

      <div className="flex w-full flex-col gap-2  px-10">
        <TextArea
          data-cy="edit-notes-area"
          disabled={!editNotes}
          rows={5}
          className="w-full"
          name="notes"
          onChange={handleChange}
          value={currentNotes}
        ></TextArea>
        <div className="flex flex-row items-center justify-end gap-2">
          {editNotes ? (
            <>
              <Button
                className="rounded-lg bg-tertiary text-primary hover:bg-primary hover:text-white"
                onClick={() => {
                  setCurrentNotes(editingSlide.notes);
                  setEditNotes(false);
                }}
              >
                Discard
              </Button>
              <Button
                data-cy="save-notes-button"
                className="rounded-lg bg-secondary text-white hover:bg-primary"
                onClick={onSaveNotes}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              data-cy="edit-notes-button"
              className={
                'rounded-lg bg-tertiary text-primary hover:bg-primary hover:text-white'
              }
              onClick={() => setEditNotes(true)}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PresentationEdit;
