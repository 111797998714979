import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RightArrow, SearchBar } from '../../components';
import CollectionCreation from '../../components/CollectionCreation/CollectionCreation';
import EditImage from '../../components/EditImage/EditImage';
import PlusIcon from '../../components/Icons/PlusIcon';
import Page from '../../components/Page/Page';
import PresentationCreation from '../../components/PresentationCreation/PresentationCreation';
import {
  useCollection,
  useDebounce,
  useImages,
  useModal,
  useSelectableElements,
} from '../../hooks';

import { ICollection, IImage } from '../../models';
import ImageCard from './components/ImageCard';
import useWheel from '../../hooks/useWheel';
import Alert from '../../services/alerts/AlertService';
import { ICreatePresentation } from '../../models/presentation.model';
import usePresentation from '../../hooks/usePresentations';
import ISlide from '../../models/presentationImage.model';
import Loader from '../../components/Loader/Loader';
import DropFileArea from '../../components/utility/DropFileArea';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import useKeys from '../../hooks/useKeys';

function Collection() {
  const [collection, setCollection] = useState<ICollection>({} as ICollection);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { getCollectionById, addImage } = useCollection();
  const { collectionId } = useParams();
  const { getImages, deleteImage } = useImages();
  const { openModal, closeModal, isOpen: modalIsOpen } = useModal();
  const [selectedCards, selectCard, clearCardSelections] =
    useSelectableElements();
  const [gridColumns, setGridColumns] = useState<number>(4);
  const { createPresentation } = usePresentation();
  const handleAddImagesToCollection = (collection: ICollection) => {
    openModal(
      <CollectionCreation
        collection={collection}
        close={closeModal}
      ></CollectionCreation>,
    );
  };
  const navigate = useNavigate();

  const uploadImages = async (imageFiles: File[]) => {
    if (!collectionId) return;

    const totalFiles = imageFiles.length;
    let filesProcessed = 0;
    const failedUploads = [];
    for (const file of imageFiles) {
      try {
        await addImage(+collectionId, file);
        filesProcessed++;
      } catch (err) {
        failedUploads.push(file.name);
        filesProcessed++;
      }
      openModal(
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex w-96 flex-col gap-6 rounded-md  bg-white p-5 text-left sm:w-3/4 md:p-10  lg:w-1/2"
        >
          <div className="w-full  px-12">
            <ProgressBar
              loadingText="Uploading images..."
              processed={filesProcessed}
              total={totalFiles}
            />
          </div>
        </div>,
      );
    }
    if (failedUploads.length !== 0) {
      const alertMsg = `${failedUploads.join('\n-')}`;
      Alert.error({
        title: 'These files did not upload successfully',
        message: alertMsg,
      });
    }
    fetchImages();
    closeModal();
  };

  const handlePresentationCreation = (images: IImage[]) => {
    if (selectedCards.length === 0)
      return Alert.info({
        title: 'No images selected!!!',
        message:
          'Select an image with double click or double tap to create a presentation',
      });

    const onSave = (presentation: ICreatePresentation) => {
      createPresentation(presentation)
        .then((presentation) => {
          closeModal();
          clearCardSelections();
          Alert.confirm(
            {
              title: 'Presentation has been created... go to the presentation?',
            },
            () => navigate(`/presentation/${presentation.id}`),
          );
        })
        .catch((err) => Alert.error({ title: err.name, message: err.message }));
    };

    openModal(
      <PresentationCreation
        images={images}
        onSave={(title: string, slides: ISlide[]) =>
          onSave({ title, slides, collectionId: collection.id })
        }
        close={closeModal}
      ></PresentationCreation>,
    );
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const fetchImages = () => {
    if (!collectionId) return;
    getImages(+collectionId, {
      search: searchTerm,
    }).then((images) => setCollection({ ...collection, images: images }));
  };

  useDebounce(searchTerm, 700, () => fetchImages());

  const handleScroll = (event: WheelEvent) => {
    if (!event.ctrlKey) return;
    event.preventDefault();
    if (event.deltaY <= -100) {
      setGridColumns((prev) => (prev + 1 > 6 ? 6 : prev + 1));
      return;
    }
    if (event.deltaY >= 100) {
      setGridColumns((prev) => (prev - 1 < 3 ? 3 : prev - 1));
      return;
    }
  };

  useWheel(window, handleScroll);

  const deleteWithKey = async (e: KeyboardEvent) => {
    if (
      (e.key === 'Delete' || e.key === 'Backspace') &&
      document.activeElement === document.body &&
      !modalIsOpen
    ) {
      const failedToDelete = [];
      let updatedImages = [...collection.images];
      const deleteImages = [...selectedCards] as IImage[];
      let filesProcessed = 0;
      for (const image of deleteImages) {
        try {
          await deleteImage(collection.id, image.id);
          selectCard(image);
          filesProcessed++;
          updatedImages = updatedImages.filter((img) => img.id !== image.id);
        } catch (err) {
          failedToDelete.push(image);
          filesProcessed++;
        }
        openModal(
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex w-96 flex-col gap-6 rounded-md  bg-white p-5 text-left sm:w-3/4 md:p-10  lg:w-1/2"
          >
            <div className="w-full  px-12">
              <ProgressBar
                loadingText="Deleting images..."
                processed={filesProcessed}
                total={deleteImages.length}
              />
            </div>
          </div>,
        );
      }
      setCollection({ ...collection, images: updatedImages });
      closeModal();
      if (failedToDelete.length === 0) {
        return;
      }
      Alert.error({
        title: 'Failed to delete images',
        message: 'Some images could not be deleted.',
      });
    }
  };

  useKeys(window, (e) => {
    deleteWithKey(e);
  });

  const openEditModal = (image: IImage) =>
    openModal(
      <EditImage
        image={image}
        close={() => {
          closeModal();
          fetchImages();
        }}
      ></EditImage>,
    );
  useEffect(() => {
    if (!collectionId) return;
    getCollectionById(+collectionId).then((data) =>
      setCollection({ ...data, images: data.images }),
    );
  }, [collectionId, getCollectionById]);

  return (
    <Page className=" bg-white">
      <div className=" flex h-full w-full flex-grow flex-col flex-wrap gap-4 p-4 py-24 md:w-3/4 lg:w-3/4 ">
        <div className="flex flex-row justify-between gap-4  ">
          <SearchBar
            data-cy="search-image-by-tag"
            label="Search collection by tag"
            onChange={handleSearch}
            className="w-full md:w-1/2"
          />
          <div className="flex flex-row gap-2">
            <button
              onClick={() =>
                collection && handleAddImagesToCollection(collection)
              }
              className="flex h-10 w-10 flex-shrink-0 flex-col items-center justify-center rounded-full bg-primary hover:bg-secondary lg:text-sm"
            >
              <PlusIcon className="translate-x-px fill-white" />
            </button>
            <button
              data-cy="export-to-presentation-button"
              onClick={() =>
                handlePresentationCreation(selectedCards as IImage[])
              }
              className="flex h-10 w-10 flex-shrink-0 flex-col items-center justify-center rounded-full bg-primary hover:bg-secondary lg:text-sm"
            >
              <RightArrow className="translate-x-px fill-white" />
            </button>
          </div>
        </div>
        {!collection.images && (
          <div className="flex h-96 w-full flex-col items-center justify-center">
            <Loader className="h-20 w-20"></Loader>
          </div>
        )}
        <div className="w-full flex-grow">
          {collection.images && (
            <DropFileArea
              dragOverStyle="bg-blue-400 p-6 transition-all duration-200 ease-in-out"
              handleFiles={uploadImages}
              style={
                {
                  gridTemplateColumns: `repeat(${gridColumns}, minmax(0, 1fr))`,
                } as React.HTMLAttributes<HTMLDivElement>
              }
              className={`${
                collection.images && collection.images.length > 0
                  ? 'my-2 grid  max-h-fit w-full  gap-2'
                  : 'flex flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-500'
              }`}
            >
              {collection.images.length > 0 &&
                collection.images.map((image, i) => (
                  <ImageCard
                    style={{ aspectRatio: '1/1' }}
                    selected={selectedCards.includes(image)}
                    onClick={() => selectCard(image)}
                    onDoubleClick={() => openEditModal(image)}
                    key={`${image.imgUrl}${i}`}
                    img={image.imgUrl}
                    className="cursor-pointer
                transition-all duration-200 ease-in-out hover:z-10 hover:scale-95 hover:shadow-xl"
                  ></ImageCard>
                ))}
            </DropFileArea>
          )}
        </div>
      </div>
    </Page>
  );
}
export default Collection;
