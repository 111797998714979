import React from 'react';
import { SearchIcon } from '../Icons';
import Input from '../Input/Input';
import { ISearchBarProps } from './types';

function SearchBar({
  label = 'Search',
  className,
  ...inputProps
}: ISearchBarProps) {
  return (
    <div className={`flex w-full items-center ${className}`}>
      <label htmlFor="simple-search" className="sr-only">
        {label}
      </label>
      <div className="relative w-full">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon />
        </div>
        <Input
          placeholder={label}
          required
          type="text"
          className="w-full pl-10"
          {...inputProps}
        />
      </div>
    </div>
  );
}

export default SearchBar;
