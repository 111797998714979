import { createContext, useState } from 'react';

type ModalServices = {
  openModal: (component: JSX.Element, configOptions?: ModalOptions) => void;
  closeModal: () => void;
  isOpen: boolean;
};
type Props = {
  children: any;
};

interface ModalOptions {
  clickOutsideModalToClose: boolean;
}

const ModalContext = createContext({} as ModalServices);

const ModalProvider = ({ children }: Props) => {
  const defaultOptions: ModalOptions = {
    clickOutsideModalToClose: true,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [options, setOptions] = useState<ModalOptions>(defaultOptions);

  const openModal = (component: JSX.Element, configOptions?: ModalOptions) => {
    configOptions && setOptions({ ...defaultOptions, ...configOptions });
    setComponent(component);
    setIsOpen(true);
  };

  const closeModal = () => {
    setComponent(null);
    setOptions(defaultOptions);
    setIsOpen(false);
  };

  const services: ModalServices = { closeModal, openModal, isOpen };
  return (
    <ModalContext.Provider value={services}>
      {children}

      {isOpen && (
        <div
          className={
            'fixed top-0 left-0 z-50  flex h-fit max-h-screen min-h-screen w-full flex-col items-center justify-center overflow-auto bg-black bg-opacity-75'
          }
          onClick={() => options.clickOutsideModalToClose && closeModal()}
        >
          {component}
        </div>
      )}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
