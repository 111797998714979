import { useState, useEffect } from 'react';

const useTimer = (
  seconds: number,
  onTick: () => void,
): [boolean, () => void, () => void] => {
  const [running, setRunning] = useState<boolean>(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (running && seconds > 0) {
      interval = setInterval(() => {
        onTick();
      }, 1000 * seconds);
    }

    return () => clearInterval(interval);
  }, [running, seconds, onTick]);

  const start = () => {
    setRunning(true);
  };

  const stop = () => {
    setRunning(false);
  };

  return [running, start, stop];
};

export default useTimer;
