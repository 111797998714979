import React from 'react';
import { IButtonProps } from './types';

function Button({
  end,
  start,
  icon,
  className,
  children,
  ...buttonProps
}: IButtonProps) {
  return (
    <button
      type="button"
      className={` ${className} inline-flex items-center  bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white transition-all duration-150 ease-in-out hover:bg-blue-800 focus:outline-none   active:scale-95 active:transform `}
      {...buttonProps}
    >
      {start && <span className={children ? 'mr-2' : ''}>{icon}</span>}

      {children}

      {end && <span className={children ? 'ml-2' : ''}>{icon}</span>}
    </button>
  );
}

export default Button;
