import { DragEvent, TouchEvent, useState } from 'react';

type Props = {
  children: string | JSX.Element | JSX.Element[];
  transition?: string;
  touchedStyle?: string;
  onDrag?: () => any;
  onDrop?: () => any;
  onDragOver?: () => any;
  onDragEnter?: () => any;
  onDragLeave?: () => any;
  onTouch?: () => any;
};

const DraggableWrapper = ({
  transition = 'translate-x-4',
  touchedStyle,
  onDrag,
  onDrop,
  onDragEnter,
  onDragLeave,
  onDragOver,
  onTouch,
  children,
}: Props) => {
  const [dragOverStyle, setDragOverStyle] = useState('');
  const [touchMove, setTouchMove] = useState(false);

  const handle = (e: DragEvent<HTMLDivElement>, onAction: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    onAction();
  };

  const handleTouch = (e: TouchEvent<HTMLDivElement>, onAction: () => void) => {
    e.stopPropagation();
    onAction();
  };

  const handleOnDrag = () => {
    setDragOverStyle('');
    onDrag && onDrag();
  };

  const handleOnDragEnter = () => {
    setDragOverStyle('');
    onDragEnter && onDragEnter();
  };

  const handleOnDrop = () => {
    setDragOverStyle('');
    onDrop && onDrop();
  };

  const handleOnDragLeave = () => {
    setDragOverStyle('');
    onDragLeave && onDragLeave();
  };

  const handleOnDragOver = () => {
    setDragOverStyle(transition);
    onDragOver && onDragOver();
  };

  const handleTouchMove = () => {
    setTouchMove(true);
  };
  const handleOnTouchEnd = () => {
    if (touchMove) {
      setTouchMove(false);
      return;
    }
    onTouch && onTouch();
  };

  return (
    <div
      draggable
      onDrag={(e) => handle(e, handleOnDrag)}
      onDrop={(e) => handle(e, handleOnDrop)}
      onDragEnter={(e) => handle(e, handleOnDragEnter)}
      onDragLeave={(e) => handle(e, handleOnDragLeave)}
      onDragOver={(e) => handle(e, handleOnDragOver)}
      onTouchEnd={(e) => handleTouch(e, handleOnTouchEnd)}
      onTouchMove={(e) => handleTouch(e, handleTouchMove)}
      className={`delay-50  w-full transition duration-100 ease-in-out ${dragOverStyle} ${touchedStyle}`}
    >
      {children}
    </div>
  );
};

export default DraggableWrapper;
