import { InputProps } from './types';

export function Input({ className, ...inputProps }: InputProps) {
  return (
    <input
      className={`${className} block w-full border border-gray-300 bg-gray-50 p-2.5 pl-5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 `}
      {...inputProps}
    ></input>
  );
}
export default Input;
