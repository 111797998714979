import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import {
  CollectionProvider,
  ImageProvider,
  PresentationProvider,
  TagProvider,
} from './components';
import { ModalProvider } from './components/Modal/ModalProvider';
import { Navbar } from './components/Navbar';
import AuthGuard from './guards/auth.guard';
import { Collection, Home, Login, Signup } from './pages';
import Presentation from './pages/Presentation/Presentation';
import PresentationsPage from './pages/Presentations/PresentationsPage';
import Footer from './components/Footer/Footer';
import PasswordRecovery from './pages/PasswordRecovery/PasswordRecovery';

function App() {
  return (
    <div className="App">
      <main className="flex  h-fit min-h-screen w-full max-w-full flex-col font-theme">
        <Navbar />
        <Routes>
          <Route path="/" element={<AuthGuard />}>
            <Route
              path="/collection"
              element={
                <CollectionProvider>
                  <ModalProvider>
                    <Home />
                  </ModalProvider>
                </CollectionProvider>
              }
            />
            <Route
              path="/collection/:collectionId"
              element={
                <CollectionProvider>
                  <PresentationProvider>
                    <ImageProvider>
                      <TagProvider>
                        <ModalProvider>
                          <Collection />
                        </ModalProvider>
                      </TagProvider>
                    </ImageProvider>
                  </PresentationProvider>
                </CollectionProvider>
              }
            />
            <Route
              path="/presentation"
              element={
                <PresentationProvider>
                  <ModalProvider>
                    <PresentationsPage />
                  </ModalProvider>
                </PresentationProvider>
              }
            />
            <Route
              path="/presentation/:presentationId"
              element={
                <PresentationProvider>
                  <Presentation />
                </PresentationProvider>
              }
            />
          </Route>

          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/password-recovery" element={<PasswordRecovery />} />
          <Route path="*" element={<Navigate replace to={'/signin'} />} />
        </Routes>
        <Footer></Footer>
      </main>
    </div>
  );
}

export default App;
