import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from '../../components';
import CollectionCreation from '../../components/CollectionCreation/CollectionCreation';
import PlusIcon from '../../components/Icons/PlusIcon';
import Page from '../../components/Page/Page';
import {
  useModal,
  useDebounce,
  useCollection,
  useSelectableElements,
} from '../../hooks';
import { ICollection } from '../../models';
import CollectionCard from './components/CollectionCard';
import useWheel from '../../hooks/useWheel';
import Alert from '../../services/alerts/AlertService';
import Loader from '../../components/Loader/Loader';
import useKeys from '../../hooks/useKeys';

function Home() {
  const [searchTerm, setSearchTerm] = useState('');
  const [gridColumns, setGridColumns] = useState<number>(3);
  const { collections, deleteCollection, setCollections, getCollections } =
    useCollection();

  const navigate = useNavigate();

  const { openModal, closeModal, isOpen: modalIsOpen } = useModal();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCollection, selectCollection, clearSelection] =
    useSelectableElements(1);

  useDebounce(searchTerm, 700, () => {
    getCollections({ search: searchTerm }).then((data: ICollection[]) =>
      setCollections(data),
    );
  });

  const handleScroll = (event: WheelEvent) => {
    if (!event.ctrlKey) return;
    event.preventDefault();
    if (event.deltaY <= -100) {
      setGridColumns((prev) => (prev + 1 > 6 ? 6 : prev + 1));
      return;
    }
    if (event.deltaY >= 100) {
      setGridColumns((prev) => (prev - 1 < 3 ? 3 : prev - 1));
      return;
    }
  };

  useWheel(window, handleScroll);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleCollectionCreation = async (collection?: ICollection) => {
    openModal(
      <CollectionCreation
        canDelete={collection ? true : false}
        collection={collection}
        close={closeModal}
      ></CollectionCreation>,
    );
  };

  const handleCollectionDelete = async (col: ICollection) => {
    if (!collections) return;
    const onConfirm = () => {
      deleteCollection(col.id)
        .then(() =>
          setCollections([
            ...collections.filter((element) => col.id !== element.id),
          ]),
        )
        .catch((err) => Alert.error({ title: err.name, message: err.message }));
    };
    Alert.confirm(
      { title: 'Delete collection:', message: col.title },
      onConfirm,
    );
  };
  const handleDeletionWithKey = (e: KeyboardEvent) => {
    if (
      (e.key === 'Delete' || e.key === 'Backspace') &&
      document.activeElement === document.body &&
      !modalIsOpen
    ) {
      handleCollectionDelete(selectedCollection[0] as ICollection);
    }
  };
  useKeys(window, handleDeletionWithKey);

  const handleSelection = (col: ICollection) => {
    if (selectedCollection.includes(col)) {
      clearSelection();
      return;
    }
    clearSelection([col]);
  };

  return (
    <Page className="bg-white">
      <div className=" flex h-full w-full flex-col flex-wrap gap-4  p-4 py-24 md:w-3/4 lg:w-3/4 ">
        <div className="flex flex-row justify-between gap-4   ">
          <SearchBar
            data-cy="search-collection"
            label="Search collections"
            onChange={handleSearch}
            className="w-full md:w-1/2"
          />
          <button
            data-cy="create-collection"
            onClick={() => handleCollectionCreation()}
            className="flex h-10 w-10 flex-shrink-0 flex-col items-center justify-center rounded-full bg-primary hover:bg-secondary lg:text-sm"
          >
            <PlusIcon className=" translate-x-px" />
          </button>
        </div>
        {!collections && (
          <div className="flex h-96 w-full flex-col items-center justify-center">
            <Loader className="h-20 w-20"></Loader>
          </div>
        )}
        <div
          data-cy="collection-container"
          style={{
            gridTemplateColumns: `repeat(${gridColumns}, minmax(0, 1fr))`,
          }}
          className="my-2 grid h-fit max-h-fit w-full  gap-2 rounded-md"
        >
          {collections &&
            collections.map((col: ICollection) => (
              <CollectionCard
                selected={selectedCollection.includes(col)}
                onSelect={() => handleSelection(col)}
                style={{ aspectRatio: '1/1' }}
                onSeeCollection={() => navigate(`${col.id}`)}
                onAddImages={() => handleCollectionCreation(col)}
                key={col.title}
                className="relative bg-white transition-all duration-200
                ease-in-out hover:z-10 hover:scale-95 hover:bg-gray-200"
                name={col.title}
                img={col.images.length ? col.images[0].imgUrl : ''}
              ></CollectionCard>
            ))}
        </div>
      </div>
    </Page>
  );
}

export default Home;
