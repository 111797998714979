import { ChangeEvent, useState } from 'react';
import { SearchBar } from '../../components';
import Page from '../../components/Page/Page';
import { useDebounce } from '../../hooks';
import usePresentation from '../../hooks/usePresentations';
import IPresentation from '../../models/presentation.model';
import PresentationCard from './component/PresentationCard';
import Alert from '../../services/alerts/AlertService';
import Loader from '../../components/Loader/Loader';

const PresentationsPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const {
    presentations,
    getPresentations,
    setPresentations,
    deletePresentation,
  } = usePresentation();

  const handleDelete = (presentationId: number) => {
    if (!presentations) return;
    const onConfirm = () => {
      deletePresentation(presentationId)
        .then(() => {
          const filteredPresentations = presentations.filter(
            (element) => presentationId !== element.id,
          );
          setPresentations(filteredPresentations);
        })
        .catch((err) => Alert.error({ title: err.name, message: err.message }));
    };

    Alert.confirm(
      { title: 'Are you sure?', confirmButtonText: 'Yes' },
      onConfirm,
    );
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const setContextPresentations = async () => {
    const data = await getPresentations({ search: searchTerm });
    setPresentations(data);
  };

  useDebounce(searchTerm, 500, setContextPresentations);

  return (
    <Page className="bg-white">
      <div className=" flex h-fit w-full flex-col flex-wrap  p-4 py-24  md:w-3/4 lg:w-3/4 ">
        <div className="mb-4 flex flex-row  justify-between ">
          <SearchBar
            data-cy="presentation-search-input"
            onChange={handleSearch}
            className="w-full md:w-1/2"
            label="Search presentation"
          ></SearchBar>
        </div>
        {!presentations && (
          <div className="flex h-96 w-full flex-col items-center justify-center">
            <Loader className="h-20 w-20"></Loader>
          </div>
        )}
        <div className="flex h-fit w-full flex-col ">
          {presentations &&
            presentations.length >= 0 &&
            presentations.map((presentation: IPresentation, i: number) => (
              <PresentationCard
                key={`${presentation.title}${i}`}
                onDelete={() => handleDelete(presentation.id)}
                className="my-4 h-72 w-full md:w-1/2"
                {...presentation}
              ></PresentationCard>
            ))}
        </div>
      </div>
    </Page>
  );
};

export default PresentationsPage;
