import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import ISlide from '../../../models/presentationImage.model';
import SlidesPreview from './SlidesPreview';
import moment from 'moment';

type Props = {
  id: number;
  className: string;
  slides: ISlide[];
  createdAt: string;
  title: string;
  onDelete: () => void;
};

const PresentationCard = ({
  id,
  slides,
  createdAt,
  title,
  onDelete,
  className = '',
}: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${className} flex  flex-col items-center justify-center gap-4  bg-white p-4`}
    >
      <div className="flex h-fit w-full flex-row justify-between">
        <div className="text-left">
          <h2 className="text-lg font-bold text-gray-800">{title}</h2>
          <p className=" text-xs text-gray-700">{`Created: ${moment(
            createdAt,
          ).format('MMMM Do YYYY, h:mm:ss a')}`}</p>
        </div>
        <div className="flex flex-row items-center justify-center gap-2">
          <Button
            data-cy="presentation-play-button"
            className="h-8  rounded-lg bg-secondary text-white hover:bg-primary"
            onClick={() => navigate(`${id}`)}
          >
            Play
          </Button>
          <Button
            data-cy="presentation-delete-button"
            className="h-8 rounded-lg bg-tertiary text-primary hover:bg-primary hover:text-white"
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      </div>
      <SlidesPreview className="h-40" slides={slides}></SlidesPreview>
    </div>
  );
};

export default PresentationCard;
