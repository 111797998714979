import React, { createContext, useEffect, useState } from 'react';
import { ICollection } from '../../models';

import { API } from '../../services/url';
import { useUserServices } from '../../hooks';

interface ICollectionContext {
  collections: ICollection[] | null;
  setCollections: (Collections: ICollection[] | null) => void;
  getCollections: (query?: CollectionQuery) => Promise<ICollection[]>;
  getCollectionById: (id: number) => Promise<ICollection>;
  createCollection: (name: string) => Promise<ICollection>;
  addImage: (collectionid: number, imageFile: File) => Promise<void>;
  deleteCollection: (id: number) => Promise<void>;
  refresh: () => Promise<void>;
}

const CollectionContext = createContext({} as ICollectionContext);

interface CollectionQuery {
  search?: string;
  orderBy?: string;
}

function CollectionProvider({ children }: { children: React.ReactNode }) {
  const { session, getAccessToken } = useUserServices();

  const [collections, setContextCollections] = useState<ICollection[] | null>(
    null,
  );

  const setCollections = (newCollections: ICollection[] | null) => {
    setContextCollections(newCollections);
  };

  const createCollection = async (name: string) => {
    const response = await fetch(`${API}collections`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      body: JSON.stringify({ title: name }),
    });
    const collection = response.json();
    return collection;
  };

  const addImage = async (
    collectionId: number,
    imageFile: File,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('file', imageFile, imageFile.name);

    const options = {
      method: 'POST',
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      body: formData,
    };
    const response = await fetch(
      `${API}collections/${collectionId}/images`,
      options,
    );
    if (!response.ok) {
      const err = await response.json();
      throw err;
    }
  };

  const getCollectionById = async (id: number): Promise<ICollection> => {
    const response = await fetch(`${API}collections/${id}`, {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    const data = await response.json();
    return data;
  };

  const getCollections = async (
    query?: CollectionQuery,
  ): Promise<ICollection[]> => {
    if (query?.search) {
      const response = await fetch(`${API}collections?title=${query.search}`, {
        headers: { Authorization: `Bearer ${await getAccessToken()}` },
      });
      const data = await response.json();
      return data;
    }
    const response = await fetch(`${API}collections`, {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    const data = await response.json();
    return data;
  };

  const deleteCollection = async (id: number) => {
    const response = await fetch(`${API}collections/${id}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    });
    if (response.status === 400) {
      const err = await response.json();
      err.name = err.message;
      err.message = err.data.join('\n- ');
      throw err;
    }
    if (response.status === 404) {
      const err = await response.json();
      throw err;
    }
  };

  const refresh = async () => {
    getCollections().then((data) => setCollections(data));
  };

  useEffect(() => {
    if (collections !== null) return;
    if (session === null) return;
    getAccessToken().then((token) => {
      fetch(`${API}collections`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((data) => data.json())
        .then((data) => {
          setCollections(data as unknown as ICollection[]);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections]);

  return (
    <CollectionContext.Provider
      value={{
        collections,
        getCollections,
        getCollectionById,
        setCollections,
        createCollection,
        addImage,
        deleteCollection,
        refresh,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
}

export { CollectionProvider, CollectionContext };
