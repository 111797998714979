import { CSSProperties } from 'react';

import HoverButton from '../HoverButton/HoverButton';

import TrashIcon from '../Icons/TrashIcon';

type Props = {
  img: string | Blob;
  alt: string;
  className: string;
  onDelete?: () => void;
  onClick?: () => void;
  selected?: boolean;
  style?: CSSProperties;
};

const PreviewCard = ({
  img,
  alt,
  className = '',
  onDelete = undefined,
  onClick = undefined,
  selected = false,
  style = undefined,
}: Props) => {
  return (
    <div
      onClick={() => onClick && onClick()}
      style={style}
      className={`relative ${className}`}
    >
      <HoverButton
        dataCy="preview-card-delete-button"
        ariaLabel="close-button"
        hover={selected}
        onClick={() => onDelete && onDelete()}
        className={
          '-right-3 -top-3 rounded-lg bg-tertiary p-2 text-primary hover:bg-primary hover:text-white'
        }
      >
        <TrashIcon className="h-6 w-6"></TrashIcon>
      </HoverButton>
      <img
        draggable={false}
        className="h-full w-full object-cover"
        src={`${img}`}
        alt={alt}
      />
    </div>
  );
};

export default PreviewCard;
