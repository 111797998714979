import archinavWelcomeLogo from '../../assets/archinavWelcomeLogo.svg';

const WelcomeToArchinav = () => {
  return (
    <div className="bg-welcomeBg sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="m-auto mt-4 mb-6 h-24 w-auto"
        src={archinavWelcomeLogo}
        alt="ArchiNav"
      />
    </div>
  );
};

export default WelcomeToArchinav;
