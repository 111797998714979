import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  confirmNewPassword,
  loginWithCredentials,
  passwordRecovery,
  refreshUserToken,
  signOut,
} from '../../services/cognito/cognito.services';
import { API } from '../../services/url';
import { IUser } from '../../models/user.model';

interface Session {
  accessToken: string;
  refreshToken: string;
  user: IUser;
}

type UserServices = {
  loginUser: (email: string, password: string) => Promise<IUser | null>;
  signupUser: (data: ISignupData) => any;
  session: Session | null;
  loadingSession: boolean;
  recoverPassword: (email: string) => Promise<void>;
  sendNewPassword: (confirmationData: {
    newPassword: string;
    code: string;
    email: string;
  }) => Promise<void>;
  userSignOut: () => Promise<void>;
  getAccessToken: () => Promise<string>;
};

export interface ISignupData {
  name: string;
  lastName: string;
  email: string;
  password: string;
}

type Props = {
  children: any;
};

const UserServiceContext = createContext({} as UserServices);

const UserServicesProvider = ({ children }: Props) => {
  const [cognitoUserPool] = useState<CognitoUserPool>(
    new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
      endpoint: process.env.REACT_APP_COGNITO_POOL_ENDPOINT as string,
    }),
  );
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [session, setSession] = useState<Session | null>(null);
  const navigate = useNavigate();
  const refresh = useCallback(async () => {
    setLoadingSession(true);
    try {
      const result = await refreshUserToken(cognitoUserPool);
      const userData = await getUserData(result.getAccessToken().getJwtToken());
      return {
        accessToken: result.getAccessToken().getJwtToken(),
        refreshToken: result.getRefreshToken().getToken(),
        user: userData,
      };
    } catch (err) {
      throw err;
    } finally {
      setLoadingSession(false);
    }
  }, [cognitoUserPool]);

  const getUserData = async (token: string): Promise<IUser> => {
    const response = await fetch(`${API}auth/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) throw Error('Expired session');
    const userData = response.json();
    return userData;
  };
  const recoverPassword = async (email: string): Promise<void> => {
    try {
      await passwordRecovery(cognitoUserPool, email);
    } catch (err) {
      throw err;
    }
  };

  const sendNewPassword = async (confirmationData: {
    newPassword: string;
    code: string;
    email: string;
  }) => {
    try {
      await confirmNewPassword(cognitoUserPool, confirmationData);
    } catch (err) {
      throw err;
    }
  };

  const loginUser = async (email: string, password: string) => {
    let userData = null;
    try {
      setLoadingSession(true);

      const result = await loginWithCredentials(
        email,
        password,
        cognitoUserPool,
      );
      userData = await getUserData(result.getAccessToken().getJwtToken());
      setSession({
        accessToken: result.getAccessToken().getJwtToken(),
        refreshToken: result.getRefreshToken().getToken(),
        user: userData,
      });
      return userData;
    } catch (err) {
      throw err;
    } finally {
      setLoadingSession(false);
    }
  };

  const signupUser = async (signupData: ISignupData) => {
    try {
      const response = await fetch(`${API}auth/register`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(signupData),
      });
      if (!response.ok) throw response.json();
      return response.status;
    } catch (err) {
      throw err;
    }
  };

  const userSignOut = async () => {
    try {
      await signOut(cognitoUserPool);
      setSession(null);
    } catch (error) {
      throw error;
    }
  };

  const getAccessToken = useCallback(
    () =>
      refreshUserToken(cognitoUserPool).then((result) => {
        return result.getAccessToken().getJwtToken();
      }),
    [cognitoUserPool],
  );

  const services: UserServices = {
    signupUser,
    loginUser,
    loadingSession,
    recoverPassword,
    sendNewPassword,
    session,
    userSignOut,
    getAccessToken,
  };

  useEffect(() => {
    if (session) return;
    refresh()
      .then((result) => {
        setSession(result);
        result.user.lastCollectionIdViewed
          ? navigate(`/collection/${result.user.lastCollectionIdViewed}`)
          : navigate('/collection');
      })
      .catch((err) => console.error(err));
  }, [session, navigate, refresh]);

  return (
    <UserServiceContext.Provider value={services}>
      {children}
    </UserServiceContext.Provider>
  );
};

export { UserServicesProvider, UserServiceContext };
