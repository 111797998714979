import Swal from 'sweetalert2';

//How to use this service:

//Import Alert class "import Alert from 'route'"

//Do not instantiate an object, just use it as a static class

//Example: Alert.confirm({title:'A custom title', message:'A custom message'}, OnConfirmCallbackFunction)

interface IAlertContent {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export default class Alert {
  //Button colors follow UI guidelines, icons colors are defaulted from the sweetAlert2 package

  static buttonConfirmColor = '#16778C';
  static buttonCancelColor = '#BAD9D3';
  static cancelTextColor = '#013440';

  static success({ title, message }: IAlertContent) {
    Swal.fire({
      title,
      text: message,
      confirmButtonColor: this.buttonConfirmColor,
      icon: 'success',
    });
  }

  static error({ title, message }: IAlertContent) {
    Swal.fire({
      title,
      text: message,
      confirmButtonColor: this.buttonConfirmColor,
      icon: 'error',
    });
  }

  static info({ title, message }: IAlertContent) {
    Swal.fire({
      title,
      text: message,
      confirmButtonColor: this.buttonConfirmColor,
      icon: 'info',
    });
  }

  static confirm(
    {
      title,
      message,
      confirmButtonText = 'Accept',
      cancelButtonText = 'Cancel',
    }: IAlertContent,
    onConfirm: () => void,
  ) {
    Swal.fire({
      title,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: this.buttonConfirmColor,
      confirmButtonText: confirmButtonText,
      cancelButtonColor: this.buttonCancelColor,
      cancelButtonText: `<p style ="color: ${this.cancelTextColor};">${cancelButtonText}</p>`,
    }).then((result) => {
      if (result.isConfirmed) {
        return onConfirm();
      }
    });
  }

  static confirmRequest(
    { title, message }: IAlertContent,
    request: () => any,
    onSuccess: () => void,
  ) {
    Swal.fire({
      title,
      text: message,
      icon: 'warning',
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: `<p style ="color: ${this.cancelTextColor};">Cancel</p>`,
      cancelButtonColor: this.buttonCancelColor,
      confirmButtonColor: this.buttonConfirmColor,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return request()
          .then((response: { data: any }) => {
            return response.data;
          })
          .catch((error: { response: { data: { message: any } } }) => {
            Swal.showValidationMessage(
              `Request failed: ${error.response.data.message}`,
            );
            const confirmButton = Swal.getConfirmButton();
            if (confirmButton) confirmButton.textContent = 'Retry';
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        return onSuccess();
      }
    });
  }
}
